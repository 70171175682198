<template>
  <div class="item-card">
    <slot name="pic">
      <div class="card-pic relative" :style="{ ...props.picStyle }">
        <div class="pic-area" :style="{ ...props.imgStyle }">
          <img :src="curItem?.classInfo?.imageUrl" alt="" />
        </div>

        <div class="pic-attr flex">
          <span
            class="flex items-center pl-5 pr-5 text-12 text-white"
            :style="{
              backgroundColor: CS_EXTERIOR[curItem?.exterior?.nameZh]?.color
            }"
          >
            <span
              class="mr-2 inline-block h-11 w-4"
              :style="{
                backgroundColor: `${CS_RARITY[curItem?.rarity?.nameZh]}`,
                height: '11px',
                borderRadius: '2px'
              }"
            ></span>
            {{ curItem?.exterior?.nameZh }}
          </span>
        </div>

        <!-- <div class="pic-line">
          <span
            class="bg block h-29"
            :style="{
              background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${CS_RARITY[curItem?.rarity?.nameZh]} 100%)`
            }"
          >
          </span>
          <span
            class="line block h-3"
            :style="{
              backgroundColor: CS_RARITY[curItem?.rarity?.nameZh]
            }"
          >
          </span>
        </div> -->
      </div>
    </slot>

    <slot name="info">
      <div class="card-info pl-10 pr-10">
        <p class="truncate pt-10 text-14 text-black">{{ curItem?.classInfo?.name }}</p>
        <p class="pt-4 text-16 text-orange">{{ monetaryUnit() }}{{ currencyTransform(curItem?.assetInfo?.evaluatePrice) }}</p>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { CS_EXTERIOR, CS_RARITY } from '~/constants/item.js'

const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  picStyle: {
    type: Object,
    default: {
      height: '144px'
    }
  },
  imgStyle: {
    type: Object,
    default: {
      padding: '12px 30px'
    }
  },
  commonDictionary: {
    type: Object,
    default: {}
  }
})

const curItem = computed(() => {
  // props.item.classInfo.tag = {}
  // for (const [rawKey, value] of Object.entries(props.item?.classInfo?.tagMap)) {
  //   const field = rawKey.split('#')[0].toLocaleLowerCase()

  //   props.item.classInfo.tag[field] = value
  // }

  // return props.item
  return handleTagMap(props.item, props.commonDictionary, 'classInfo')
})
</script>

<style lang="scss" scoped>
.item-card {
  width: 100%;
  height: 100%;
  background: $white-color;
  border-radius: 10px;
  overflow: hidden;

  .card-pic {
    // background-image: url('~/assets/images/common/common-item-bg.png');
    background-size: 100% 100%;
    background-color: #f7f9ff;
    border-radius: 10px;
    .pic-attr {
      position: absolute;
      top: 0;
      left: 0;

      span {
        height: 17px;
        line-height: 17px;
        border-radius: 5px 0px 5px 0px;
      }
    }

    .pic-line {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      .bg {
        opacity: 0.3;
      }
    }
  }
}
</style>
