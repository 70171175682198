<template>
  <div>
    <div class="index-container relative">
      <!-- <div class="banner-container h-250">这是 banner区域</div> -->
      <div class="border-market mb-30 flex h-180 justify-between p-30">
        <div class="w-230">
          <div class="mb-18 flex items-center text-16 font-semibold" @click="showKlinecharts">
            <mytooltip :class="'w-350'" placement="bottom">
              <span class="mr-5 cursor-pointer">{{ $t('大盘指数') }}</span>

              <template #content>
                <div class="py-15 text-14 text-gray-light">
                  <p>{{ $t('指数的起始点为1000，起始时间为2024年2月5日，每分钟计算一次大盘指数。') }}</p>
                  <p>（{{ $t('为了更准确、全面地反映市场行情，从流通性、存世量、价格等多个维度筛选能真实反映市场趋势的皮肤。将这些皮肤进行指数统计。') }}）</p>
                  <p>{{ $t('计算方法：') }}</p>
                  <p>{{ $t('筛选出真实反映市场趋势的皮肤，以皮肤的全网在售数量赋予相应权重') }}</p>
                  <p>{{ $t('起始市值总和 = 累加每个皮肤起始最低售价*权重') }}</p>
                  <p>{{ $t('今日指数 = 1000点 * 今日市值总和 / 起始市值总和') }}</p>
                </div>
              </template>
            </mytooltip>
            <span class="cursor-pointer"><img src="~/assets/images/index/border-arrow.webp" alt="" /></span>
            <span v-if="borderData.riseFallType != 'FLAT' && borderData.riseFallDays > 1" :class="borderData.riseFallType" class="ml-10 flex w-100 items-center">
              <img v-if="borderData.riseFallType === 'UP'" class="mr-5 h-16 w-20" src="~/assets/images/index/up.webp" alt="" />
              <img v-if="borderData.riseFallType === 'DOWN'" class="mr-5 h-16 w-20" src="~/assets/images/index/down.webp" alt="" />
              <span>{{ borderData.riseFallDays }}{{ $t('天') }}</span>
            </span>
          </div>
          <div class="mb-19 flex">
            <div>
              <div>
                <span :class="[borderData?.diffYesterday > 0 ? 'text-color-red' : 'text-color-green']" class="text-color-red mr-7 text-28 font-semibold"
                  ><counter :val="borderData.broadMarketIndex" :decimal-places="2"></counter
                ></span>
              </div>
              <div>
                <span :class="[borderData?.diffYesterday > 0 ? 'text-color-red' : 'text-color-green', 'mr-7', 'text-14']"
                  >{{ borderData?.diffYesterday > 0 ? `+` : `-` }}<counter :val="borderData?.diffYesterday * 1" :decimal-places="2"></counter
                ></span>
                <span :class="[borderData?.diffYesterdayRatio > 0 ? 'text-color-red' : 'text-color-green', 'mr-7', 'text-14']"
                  >{{ borderData?.diffYesterdayRatio > 0 ? `+` : `-` }}<counter :val="borderData?.diffYesterdayRatio * 1" :decimal-places="2"></counter>%</span
                >
              </div>
            </div>
            <div>
              <indexChart
                class="inline-block"
                :data="borderData?.historyMarketIndexList"
                :direction="borderData?.diffYesterday"
                :last-day-end="true"
                :x-key="0"
                :y-key="1"
                @click="showKlinecharts"
              />
            </div>
          </div>
          <!-- <ClientOnly>
              <div class="text-color-gray text-14">{{ $t('当前时间') }} ：{{ nowTime }}</div>
            </ClientOnly> -->
        </div>
        <div class="flex w-680 justify-between">
          <div class="w-320">
            <!-- <div class="mb-18 text-16 font-semibold">今日新增 / 昨日新增</div> -->
            <div class="flex">
              <div class="w-180">
                <div class="mb-18">
                  <mytooltip placement="bottom" :content="$t('开箱、汰换、游戏掉落的方式产生的皮肤数量和价值')">
                    <span class="cursor-pointer text-16 font-semibold">{{ $t('今日新增') }}</span>
                  </mytooltip>
                </div>
                <div class="mb-24">
                  <span class="text-color-gray mr-5 inline-block">{{ $t('量') }}</span>
                  <span class="text-color-primary text-16"><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.addNum * 1"></counter></span>
                </div>
                <div>
                  <span class="text-color-gray mr-5 inline-block">{{ $t('额') }}</span>
                  <span class="text-color-orange text-16"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.todayStatistics?.addValuation * 1)"></counter
                  ></span>
                </div>
              </div>
              <div class="w-150">
                <div class="mb-18 text-16 font-semibold">{{ $t('昨日新增') }}</div>
                <div class="mb-24">
                  <span class="text-color-primary text-16"><counter :decimal-places="3" :separator="''" :val="borderData?.yesterdayStatistics?.addNum * 1"></counter></span>
                </div>
                <div>
                  <span class="text-color-orange text-16"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.yesterdayStatistics?.addValuation * 1)"></counter
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-320">
            <div class="flex">
              <div class="w-180">
                <div class="mb-18">
                  <mytooltip placement="bottom" :content="$t('追踪全球用户库存的变动，精准分析得出成交量')">
                    <span class="cursor-pointer text-16 font-semibold">{{ $t('今日成交') }}</span>
                  </mytooltip>
                </div>
                <div class="mb-24">
                  <span class="text-color-gray mr-5 inline-block">{{ $t('量') }}</span>
                  <span class="text-color-primary text-16"><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.tradeNum * 1"></counter></span>
                </div>
                <div>
                  <span class="text-color-gray mr-5 inline-block">{{ $t('额') }}</span>
                  <span class="text-color-orange text-16"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.todayStatistics?.turnover * 1)"></counter
                  ></span>
                </div>
              </div>
              <div class="w-150">
                <div class="mb-18 text-16 font-semibold">{{ $t('昨日成交') }}</div>
                <div class="mb-24">
                  <span class="text-color-primary text-16"><counter :decimal-places="3" :separator="''" :val="borderData?.yesterdayStatistics?.tradeNum * 1"></counter></span>
                </div>
                <div>
                  <span class="text-color-orange text-16"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.yesterdayStatistics?.turnover * 1)"></counter
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="w-160">
              <div class="mb-18 text-16 font-semibold">{{ $t('CS2在线人数') }}</div>
              <div class="flex">
                <div class="w-180">
                  <div class="mb-24">
                    <span class="mr-5">{{ $t('当前在线') }} </span>
                    <span class="text-color-primary text-16"
                      ><counter :need-format="false" :val="borderData?.cs2Online?.cs2OnlineNum * 1"></counter
                    ></span>
                  </div>
                  <div>
                    <span class="mr-5">{{ $t('月活玩家') }}</span>
                    <span class="text-color-primary text-16"
                      ><counter :need-format="false" :val="borderData?.cs2Online?.cs2MonthlyActiveNum * 1"></counter
                    ></span>
                  </div>
                </div>
              </div>
            </div> -->
        </div>

        <div class="w-270">
          <div class="mb-18">
            <mytooltip
              :class="'w-300'"
              placement="bottom"
              :content="$t('饰品存世量仅统计匕首、手套、步枪、手枪、微型冲锋枪、霰弹枪和机枪的饰品数量，印花、武器箱等不含磨损度的物品将不会被计入存世量统计中。')"
            >
              <span class="cursor-pointer text-16 font-semibold">{{ $t('饰品存世量') }}</span>
            </mytooltip>
          </div>
          <div class="mb-19">
            <span class="inline-block text-left text-28 font-semibold" :style="{ width: `${borderData?.surviveNum?.length * 20}px` }"
              ><counter :val="borderData?.surviveNum * 1" :need-format="false" :use-easing="false"></counter
            ></span>
            <span class="text-color-gray text-14">{{ $t('件') }} </span>
          </div>
          <div class="text-color-gray text-14">{{ $t('拥有饰品的人数') }}:<counter :val="borderData?.holdersNum * 1" :need-format="false"></counter> {{ $t('人') }}</div>
        </div>
      </div>
      <!-- <div class="today-data mb-30 font-semibold">
          今日新增饰品
          <span class="text-color-primary"><counter :val="categoryData?.todayAddSkinNum" :decimal-places="3"></counter> 件</span>
          估值
          <span class="text-color-orange"><counter :val="categoryData?.todayAddValuation" :decimal-places="3"></counter>元</span>
          ，较昨日同时段增量
          <span :class="categoryData?.diffYesterdayNum > 0 ? 'text-color-red' : 'text-color-green'"
            >{{ categoryData?.diffYesterdayNum > 0 ? '+' : '' }}<counter :val="categoryData?.diffYesterdayNum"></counter> 件</span
          >
        </div>
        <div class="category-data-container mb-30 flex overflow-x-auto">
          <div v-for="(category, index) in categoryData?.statisticsList" :key="index" class="category-data-item mr-13 flex w-270 rounded p-20">
            <div class="w-104">
              <div class="h-104">
                <img :src="`https://resources.zbt.com/steam-stock/index/${category.itemType}.png`" alt="" />
              </div>
              <div class="text-16 font-semibold">
                {{ category.itemTypeZh }}
              </div>
            </div>
            <div class="w-144">
              <div class="text-color-gray h-95 border-b-[1px] border-[#EFF1F5]">
                <div class="mb5 flex items-center justify-between">
                  <span>存世量</span>
                  <span class="text-color-primary text-16">{{ category.surviveNum }}</span>
                </div>

                <div class="flex items-center justify-between">
                  <span>新增</span>
                  <span class="text-16">{{ category.addNum }}件</span>
                </div>
                <div class="mb5 flex items-center justify-between">
                  <span>估值</span>
                  <span class="text-color-orange text-16">{{ category.addValuation }}</span>
                </div>
              </div>
              <el-carousel height="30px" direction="vertical" :autoplay="true">
                <el-carousel-item v-for="item in category.defIndexList" :key="item.name">
                  <div class="flex items-center justify-between pt-10 text-gray-light">
                    <span>{{ item.name }}</span>
                    <span><i class="iconfont icon-shangsheng text-14"></i>{{ numFormat(item.addNum) }}件</span>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div> -->

      <!-- 最近新增饰品 -->
      <div class="new-skin mb-30">
        <el-tooltip class="box-item" effect="dark" :content="$t('开箱、汰换、游戏掉落的方式产生的饰品数量和价值')" :popper-class="'w-300'">
          <span class="skin-title text-16 font-bold text-[#252525]">{{ $t('最近新增饰品') }}</span>
        </el-tooltip>

        <el-skeleton style="width: 100%" :animated="true" :loading="skinData.list.length <= 0">
          <template #template>
            <div>
              <el-skeleton-item v-for="i in 6" :key="i" variant="rect" style="width: 220px; height: 210px; margin-right: 10px; margin-top: 20px; display: inline-block" />
            </div>
          </template>
          <template #default>
            <ClientOnly>
              <div class="skin-list py-20" @mouseenter="enterSkin" @mouseleave="leaveSkin">
                <div id="J_SkinWrapper" class="skin-wrapper" :class="skinData.bIsAnimation ? 'animation' : ''">
                  <a
                    v-for="(item, index) in skinData.list"
                    :key="index"
                    class="skin-item relative mr-16 h-210 w-220 cursor-pointer"
                    :href="localePath(`/inventory/${item.steamAccount.steamToken}?&hash=${item.asset.assetInfo.fingerprint}`)"
                  >
                    <itemCard :item="item.asset" :common-dictionary="commonDictionary" />
                    <div class="item-user">
                      <el-avatar :size="50" :src="item.steamAccount.avatar">
                        <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                      </el-avatar>
                      <p class="mt-6 text-16 font-bold">{{ item.steamAccount.nickname }}</p>
                      <p class="mt-5 flex items-center justify-center">
                        <i class="iconfont icon-jiantou text-color-lightgray mr-5"></i>
                        <span class="text-color-lightgray text-12">{{ $t('前往库存查看') }} </span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </ClientOnly>
          </template>
        </el-skeleton>
      </div>

      <itemlist :common-dictionary="commonDictionary" />
    </div>

    <client-only>
      <div v-if="klinechartsVisible">
        <klinecharts :dialog-visible="klinechartsVisible" @close="klinechartsVisible = false" />
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import itemlist from './indexcomponents/itemList.vue'
import { numFormat } from '~/utils/index'
import { getTotalData, getNewestItems } from '~/api/steam'
import { getCommonDictionary } from '~/api/common'
import indexChart from './indexcomponents/indexChart.vue'
import counter from './indexcomponents/counter.vue'
import klinecharts from './indexcomponents/klinecharts.vue'
import itemCard from '~/components/common/itemCard.vue'
import banner from '~/components/layout/banner.vue'
import wsConnect from '~/composables/websocket'
import mytooltip from '~/components/common/mytooltip.vue'
import dayjs from 'dayjs'
const { t } = useI18n()
const $t = t
// 更改页面模板

useHead({
  title: $t('SteamDt-CS2饰品数据_CS2市场大盘_CS2饰品市场'),
  meta: [
    {
      name: 'description',
      content: $t('SteamDt为您提供CS2饰品的趋势分析、趋势量、成交量、新增量、换手率、持有人数、挂刀比例及价格走势等数据，更多CS2饰品数据就上SteamDt。')
    },
    {
      name: 'keywords',
      content: $t('CS2市场, CSGO市场, CS2饰品市场,CS2市场大盘,CS2市场大盘数据分析,CS2饰品数据,CS2饰品趋势榜,CS2饰品涨跌榜')
    }
  ]
})

let borderData = ref({})
let categoryData = ref({})
let nowTime = ref('')
let historyMarketIndexList = ref([])
const skinData = reactive({
  // 源数据
  list: [],
  // 新增饰品数据
  newList: [],
  index: 0,
  limit: 20,
  timer: null,
  bIsAdd: false,
  bIsAnimation: false
})
const klinechartsVisible = ref(false)

const [{ data: borderRes }] = await Promise.all([getTotalData()])
borderData.value = borderRes
// skinData.list = newestItems
historyMarketIndexList.value = borderData.value.historyMarketIndexList.sort(function (a, b) {
  const aTime = dayjs(a[0]).valueOf()
  const bTime = dayjs(b[0]).valueOf()
  return aTime - bTime
})
// categoryData.value = categoryRes
nowTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')

const { data: commonDictionary } = await useLazyAsyncData(getCommonDictionary, {
  server: false
})

// let { data: borderData } = await getTotalData();
// let { data: categoryData } = await getCategoryData();

// console.log("borderData", borderData);
// console.log("categoryData", categoryData);
// console.log("pageData", pageData);

onMounted(async () => {
  // setInterval(() => {
  //   getBorderData()
  //   // getcategorydata()
  // }, 60000)
  setInterval(() => {
    nowTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')
  }, 1000)
  const { data: newestItems } = await getNewestItems()
  skinData.list = newestItems
  // 插入新饰品
  startAddSkin()

  // 监听动画停止
  setTimeout(() => {
    document.getElementById('J_SkinWrapper')?.addEventListener('animationend', () => {
      skinData.bIsAnimation = false
    })
  }, 200)

  await wsConnect.init('', [
    {
      key: 'NEW_FINGERPRINT_ASSET',
      callBack: function (event) {
        const curEvaluatePrice = Number(event.data.asset.assetInfo.evaluatePrice) * Number(monetaryRate())
        event.data.asset.assetInfo.evaluatePrice = curEvaluatePrice.toFixed(2)
        skinData.newList.push(event.data)
      }
    },
    {
      key: 'STEAM_TOTAL_STATISTICS_PUSH',
      callBack: function (event) {
        // console.log('ws event', event)
        // if (event.data?.todayStatistics) {
        //   event.data.todayStatistics.addValuation = Number(event.data.todayStatistics?.addValuation) * Number(monetaryRate())
        //   event.data.todayStatistics.turnover = Number(event.data.todayStatistics?.turnover) * Number(monetaryRate())
        // }

        // if (event.data?.yesterdayStatistics) {
        //   event.data.yesterdayStatistics.addValuation = Number(event.data.yesterdayStatistics?.addValuation) * Number(monetaryRate())
        //   event.data.yesterdayStatistics.turnover = Number(event.data.yesterdayStatistics?.turnover) * Number(monetaryRate())
        // }
        const temp = Object.assign(borderData.value, event.data)
        borderData.value = temp
      }
    }
  ])
})
function getBorderData() {
  getTotalData().then(res => {
    borderData.value = res.data
    historyMarketIndexList.value = borderData.value.historyMarketIndexList.sort(function (a, b) {
      const aTime = dayjs(a.calculateTime).valueOf()
      const bTime = dayjs(b.calculateTime).valueOf()
      return aTime - bTime
    })
  })
}

// 移入饰品列表
function enterSkin() {
  stopAddSkin()
}
// 移出饰品列表
function leaveSkin() {
  const { bIsAdd } = skinData

  if (!bIsAdd) {
    startAddSkin()
  }
}
// 开启插入轮询
function startAddSkin() {
  const { newList } = skinData

  skinData.timer = setInterval(() => {
    if (skinData.index <= newList.length - 1) {
      addSkin(skinData.index)
      skinData.index++
      skinData.bIsAdd = false
    } else {
      // stopAddSkin()
      skinData.bIsAdd = true
    }

    // 监听动画停止
    // setTimeout(() => {
    //   skinData.bIsAnimation = false
    // }, 1000)
  }, 3000)
}
// 停止插入轮询
function stopAddSkin() {
  clearInterval(skinData.timer)
  skinData.timer = null
}
// 插入列表
function addSkin(curIndex) {
  const { list, newList, limit } = skinData

  if (list.length >= limit) {
    skinData.list.pop()
  }
  skinData.list.unshift(newList[curIndex])
  skinData.bIsAnimation = true
}

// 链接跳转
function goLink(url) {
  const router = useRouter()
  router.push(url)
}

// 显示KLineChart
function showKlinecharts() {
  klinechartsVisible.value = true
}
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');

.index-container {
  z-index: 2;
  margin-top: -15px;
  .banner-container {
    // margin-top: -59px;
    color: red;
    background-color: #000;
  }
  .border-market {
    // background: #f8faff;
    // background: url(~/assets/images/index/border-market-bg.png) center no-repeat;
    // box-shadow: 0px -3px 0px 0px #acc6ff;
    border-radius: 8px;
    background-color: #f7f9ff;
    .UP {
      color: #ff0020;
    }
    .DOWN {
      color: #0dab62;
    }
  }
  .category-data-container {
    .category-data-item {
      background: #fff;
    }
  }

  .new-skin {
    .skin-list {
      width: 1420px;
      overflow-x: hidden;
      position: relative;
      left: -10px;
      padding-left: 10px;
    }

    .skin-wrapper {
      width: 23600px;
      font-size: 0;
      position: relative;

      &.animation {
        animation: move 1s ease;
      }
    }

    .skin-item {
      display: inline-block;
      overflow: hidden;
      border-radius: 10px;
      transition: all 0.3s;
      .item-user {
        width: 100%;
        text-align: center;
        height: 110px;
        background: $white-color;
        // box-shadow: 0px -3px 0px 0px #acc6ff;
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: -138px;
        transition: bottom 0.3s;

        .el-avatar {
          margin: -25px auto 0;
        }
      }
      &:hover {
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
          rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
          rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
      }
      &:hover .item-user {
        bottom: 0;
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
          rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
          rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
      }
    }

    @keyframes move {
      0% {
        left: -236px;
      }
      100% {
        left: 0;
      }
    }
  }
}
</style>
